import {logError} from '../../../services/errors/errorReporting';
import {logPerfAndErrors} from '../opfs';
import {CachedDataWithSize, LongTermCache} from '../types';

/**
 * CacheApiCache is an implementation of the LongTermCache interface using the
 * Cache API available in modern browsers. This class provides methods to
 * interact with the browser's cache storage, allowing for storing, retrieving,
 * checking, and deleting cached data. It is designed to handle JSON-serializable
 * data and includes performance logging for cache operations.
 *
 * The Cache API is specifically designed for managing network request/response
 * caching, whereas OPFS (Origin Private File System) is a more general-purpose
 * file storage system. The Cache API is integrated with the browser's fetch API,
 * making it more suitable for caching HTTP responses. OPFS is considered a legacy
 * approach for caching purposes, and the Cache API should be preferred.
 * More information can be found on the MDN Web Docs:
 * https://developer.mozilla.org/en-US/docs/Web/API/Cache
 */

type PerfContext = {
  alwaysLog: boolean;
  logToRum: boolean;
  size: number | null;
};

const CONTENT_LENGTH_HEADER = 'cache-content-length';

export class CacheApiCache implements LongTermCache {
  private openedCache: Cache | null = null;
  private browserHasCache = true;

  constructor(
    public readonly cacheName: string = 'wandb-graphql-cache',
    public readonly cacheVersion: string = 'v1'
  ) {}

  async get<T>(key: string): Promise<CachedDataWithSize<T> | null> {
    const cache = await this.ensurePrepared();
    if (cache == null) {
      return null;
    }
    const context: PerfContext = {
      alwaysLog: true,
      logToRum: true,
      size: null,
    };

    const request = new Request(key);
    const cachedResponse = await logPerfAndErrors(
      'cache.match',
      async () => {
        const cachedResponse = await cache.match(request);
        const contentLength = cachedResponse?.headers.get(
          CONTENT_LENGTH_HEADER
        );
        context.size = contentLength ? parseInt(contentLength) : null;

        if (context.size === null || isNaN(context.size)) {
          context.size = null;
        }
        return cachedResponse;
      },
      'CacheApiCache',
      context
    );

    let cachedData: T | null = null;

    if (cachedResponse) {
      cachedData = await logPerfAndErrors(
        'cache.deserialize',
        () => cachedResponse.json(),
        'CacheApiCache',
        context
      );
    }
    return cachedData
      ? {data: cachedData, bytesReadFromCache: context.size ?? 0}
      : null;
  }

  async hasKey(key: string): Promise<boolean> {
    const cache = await this.ensurePrepared();
    return cache?.match(key) != null;
  }

  async set<T>(key: string, data: T): Promise<void> {
    const cache = await this.ensurePrepared();
    if (cache == null) {
      return;
    }
    const request = new Request(key);
    const context: PerfContext = {alwaysLog: true, logToRum: true, size: null};
    const responseToCache = await logPerfAndErrors(
      'cache.serialize',
      async () => {
        const serializedData = JSON.stringify(data);
        context.size = serializedData.length;
        const headers = new Headers({
          [CONTENT_LENGTH_HEADER]: context.size.toString(),
        });
        return new Response(serializedData, {headers});
      },
      'CacheApiCache',
      context
    );

    if (responseToCache) {
      await logPerfAndErrors(
        'cache.put',
        () => cache.put(request, responseToCache),
        'CacheApiCache',
        context
      );
    }
  }

  async delete(key: string): Promise<void> {
    const cache = await this.ensurePrepared();
    if (cache == null) {
      return;
    }
    const request = new Request(key);
    await cache.delete(request);
  }

  private async ensurePrepared(): Promise<Cache | null> {
    if (this.openedCache == null && this.browserHasCache) {
      try {
        this.openedCache = await caches.open(
          `${this.cacheName}-${this.cacheVersion}`
        );
      } catch (error) {
        logError(`Error opening cache: ${error}, caching disabled`);
        this.browserHasCache = false;
      }
    }
    return this.openedCache;
  }
}
