import * as Sentry from '@sentry/react';
import {
  TrackedWandbLoader,
  TrackedWandbLoaderProps,
  TrackedWaveLoader,
} from '@wandb/weave/common/components/WandbLoader';
import {TailwindContents} from '@wandb/weave/components/Tailwind';
import React, {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router';
// this will disappear when the loaders are migrated to the new loader
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Loader as SemanticLoaderBase,
  StrictLoaderProps,
} from 'semantic-ui-react';

import {envIsDev} from './../../config';
import {useSelector} from './../../state/hooks';

declare global {
  interface Window {
    WANDB_LOG_LOADERS?: boolean;
  }
}

/**
 * Allow an override which lets us log the name of a loader. It's written this way so that we can selectively toggle it in prod since certain loaders that show in prod don't show in dev, and they're exceedingly hard to go track down
 */
const shouldLogLoaders = () => window?.WANDB_LOG_LOADERS ?? (false && envIsDev);

const useDevDebugging = (name: string, loaderType: 'wave' | 'spinner') => {
  useEffect(() => {
    const mount = performance.now();

    if (shouldLogLoaders()) {
      console.log(`${name} mounted at ${mount}`);
    }
    return () => {
      if (shouldLogLoaders()) {
        const duration = performance.now() - mount;
        console.log(`${name} (${loaderType}) unmounted in ${duration}ms`);
      }
    };
  }, [name, loaderType]);

  return null;
};

export const SemanticLoader = (props: StrictLoaderProps & {name: string}) => {
  useDevDebugging(props.name, 'spinner');

  return <SemanticLoaderBase {...props} />;
};

export const InstrumentedWaveLoader = (
  props: Omit<TrackedWandbLoaderProps, 'track'> & {
    size: 'small' | 'huge';
  }
) => {
  const location = useLocation();
  const viewerData = useSelector(state => state.viewer.viewer);

  const addData = useCallback(
    () => ({
      entity: viewerData?.entity || null,
      location: location.pathname,
      organizations: viewerData?.organizations.map(org => org.id) || [],
      user: viewerData?.id || null,
    }),
    [location.pathname, viewerData]
  );

  useDevDebugging(props.name, 'wave');

  return (
    <TrackedWaveLoader
      samplingRate={0.0025}
      {...props}
      captureException={Sentry.captureException}
      profilingCb={addData}
      // @ts-ignore
      track={window.analytics?.track}
    />
  );
};

export const InstrumentedCenteredWaveLoader = (
  props: Omit<TrackedWandbLoaderProps, 'track'> & {
    size: 'small' | 'huge';
  }
) => {
  return (
    <TailwindContents>
      <div className="flex h-full w-full items-center justify-center">
        <InstrumentedWaveLoader {...props} size="huge" />
      </div>
    </TailwindContents>
  );
};

export const InstrumentedFloatingWaveLoader = (
  props: Omit<TrackedWandbLoaderProps, 'track'> & {
    size: 'small' | 'huge';
  }
) => {
  return (
    <TailwindContents>
      <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center">
        <InstrumentedWaveLoader {...props} size="huge" />
      </div>
    </TailwindContents>
  );
};

export const InstrumentedLoader = (
  props: Omit<TrackedWandbLoaderProps, 'track'> & StrictLoaderProps
) => {
  const location = useLocation();
  const viewerData = useSelector(state => state.viewer.viewer);

  const addData = useCallback(
    () => ({
      entity: viewerData?.entity || null,
      location: location.pathname,
      organizations: viewerData?.organizations.map(org => org.id) || [],
      user: viewerData?.id || null,
    }),
    [location.pathname, viewerData]
  );

  useDevDebugging(props.name, 'spinner');
  return (
    <TrackedWandbLoader
      samplingRate={0.0025}
      {...props}
      captureException={Sentry.captureException}
      profilingCb={addData}
      // @ts-ignore
      track={window.analytics?.track}
    />
  );
};
