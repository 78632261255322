import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useSyncedPollingSubscriber} from '../../state/polling/hooks';
import {Query} from '../../state/runs/types';
import {BucketedQueryManager} from '../PanelRunsLinePlot/bucketedData/bucketedQueryManager';
import {Range} from '../PanelRunsLinePlot/common';
import {Zoom} from '../PanelRunsLinePlot/types';

export interface SharedPanelQueryState {
  query: Query | null;
  result: any;
}

interface SharedPanelStateContextType {
  bucketedQueryManager: BucketedQueryManager;
  queryState: SharedPanelQueryState;
  setQueryState: (queryState: SharedPanelQueryState) => void;
  setUserZoomState: React.Dispatch<React.SetStateAction<Zoom>>;
  setXDomainQuery: (xDomainQuery: Range) => void;
  userZoomState: Zoom;
  xDomainQuery: Range;
}

export const SharedPanelStateContext =
  createContext<SharedPanelStateContextType | null>(null);

export const SharedPanelStateContextProvider = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: {
    [key: string]: unknown;
    xAxisMin?: number | null;
    xAxisMax?: number | null;
  };
}) => {
  const [queryState, setQueryState] = useState<SharedPanelQueryState>({
    query: null,
    result: null,
  });
  const [userZoomState, setUserZoomState] = useState<Zoom>({});
  const [xDomainQuery, setXDomainQuery] = useState<Range>({
    min: config.xAxisMin ?? null,
    max: config.xAxisMax ?? null,
  });
  const setXDomainQueryChecked = useCallback(
    (xDomainQuery: Range) => {
      setXDomainQuery(curVal =>
        curVal.min === xDomainQuery.min && curVal.max === xDomainQuery.max
          ? curVal
          : xDomainQuery
      );
    },
    [setXDomainQuery]
  );
  const [bucketedQueryManager] = useState<BucketedQueryManager>(
    () => new BucketedQueryManager()
  );
  const {lastTickedAt} = useSyncedPollingSubscriber('BucketedQueryManager');

  useEffect(() => {
    bucketedQueryManager.poll();
  }, [bucketedQueryManager, lastTickedAt]);

  const value = useMemo(
    () => ({
      bucketedQueryManager,
      queryState,
      setQueryState,
      setUserZoomState,
      setXDomainQuery: setXDomainQueryChecked,
      userZoomState,
      xDomainQuery,
    }),
    [
      queryState,
      userZoomState,
      xDomainQuery,
      bucketedQueryManager,
      setXDomainQueryChecked,
    ]
  );

  return (
    <SharedPanelStateContext.Provider value={value}>
      {children}
    </SharedPanelStateContext.Provider>
  );
};

export const useSharedPanelState = (): SharedPanelStateContextType => {
  const ctx = useContext(SharedPanelStateContext);
  if (ctx == null) {
    throw new Error(
      'useSharedPanelState must be used within a SharedPanelStateContextProvider'
    );
  }
  return ctx;
};
