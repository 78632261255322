import type {LogsGlobal} from '@datadog/browser-logs';

import {frontendPerfLoggingEnabled} from '../../config';
import {getDatadogLogs, getDatadogRum} from '../datadog';
import {
  CONSOLE_REPORT_SLOW_EVENT_MILLIS,
  MIN_NETWORK_ELAPSED_TIME_MILLIS,
  MIN_REPORT_TO_SERVER_TIME_MILLIS,
  PERF_LOG_PREFIX,
  THROTTLED_MIN_NETWORK_ELAPSED_TIME_MILLIS,
} from './constants';
import {
  beginTtiSession,
  EndReason,
  EndReasons,
  endTtiSession,
  TtiRecord,
} from './timeToInteractive';

// Internal only - this is the One True Place to emit log lines related to perf events.
const perfConsoleLog = (...args: any[]) => {
  if (!frontendPerfLoggingEnabled() || args.length < 1) {
    return;
  }
  // to preserve any colors set in the text, we need the first arg to be
  // kept in the first position
  console.log(`${PERF_LOG_PREFIX} ${args[0]}`, ...args.slice(1));
};

const perfStat = ({
  name,
  value,
  interestingThreshold,
  context,
}: {
  name: string;
  value: number;
  interestingThreshold: number;
  context?: Record<string, any>;
}) => {
  if (value > interestingThreshold) {
    const logLine = `PerfStat ${name}:${value}`;
    perfConsoleLog(logLine, context ?? '');

    const ddContext = {...context};
    ddContext[name] = value;
    logToDataDog(logLine, ddContext);
  }
};

// You should generally prefer a method that filters what is sent,
// but this exists if you really just want to send something directly to DD
export const logToDataDog = (
  message: string,
  context?: Record<string, any>
) => {
  getDatadogLogs().logger.info(message, context);
};

const logCustomActionToDatadog = (
  actionName: string,
  context?: Record<string, any>
) => {
  getDatadogRum().addAction(actionName, context);
};

export const getDefaultPageStats = () => ({
  // Note on time keeping: We don't use performance.mark()/.measure() because
  // (per https://3perf.com/blog/react-monitoring/) it is memory intensive
  startTimeMs: Date.now(),
  pageMaxLongTask: 0,
});

type AnalyticsCallWithDuration = (val: {duration: number}) => void;
let pageStats = getDefaultPageStats();

const endPageView = (pageEndReason: EndReason) => {
  endTtiSession(pageEndReason);
  perfStat({
    name: 'PageMaxLongTask',
    value: pageStats.pageMaxLongTask,
    interestingThreshold: 0 /* always report, so that we have a semi-accurate %ile curve */,
    context: {pageEndReason},
  });
};

// we pass these analytics callbacks in since we are in @wandb/weave/common, and the analytics
// service is tied to the app, so it shouldn't go in common.
export const startProfilerPageView = (
  trackPageTti?: AnalyticsCallWithDuration
) => {
  endPageView(EndReasons.SPA_NAV);
  beginTtiSession((record: TtiRecord) => {
    trackPageTti?.({duration: record.tti});
    perfStat({
      name: 'PageTTI',
      value: record.tti,
      interestingThreshold: 0 /* we always want to send TTI data*/,
      context: {
        pageEndReason: record.endReason,
      },
    });
  });
  pageStats = getDefaultPageStats();

  perfConsoleLog('Profiler page view start');
};

if (typeof window !== 'undefined') {
  // We need to detect when the user navigates away from the current page. Unfortunately,
  // the window 'unload' event is no longer trustworthy on mobile.
  // source: https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
  // The new best choices for what event to handle as "page is unloaded" appear to be pagehide
  // and visibilitychange - I chose visibilitychange since it's fired also fired before
  // transitioning to freezing and "Transitioning to hidden is the last event that's
  // reliably observable by the page, so developers should treat it as the likely end of
  //  the user's session". However, it'll also fire before tab switches/etc so it's
  // going to fire earlier than wanted some times.
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      // note that we ignore event.persisted since we always want to
      // end the TTI session - it relies on timing data that
      // is not reliable when page is frozen.
      endPageView(EndReasons.LEAVE_PAGE);
    }
  });
}

const updateProfilerContextInner = (name: string, value?: string) => {
  // when value is undefined, it won't include that in context
  getDatadogRum().setGlobalContextProperty(name, value);
};

export const updatePerformanceMonitoringRoute = (routeName?: string) => {
  updateProfilerContextInner('routeName', routeName);
  getDatadogRum().startView({name: routeName});
};

export const updatePageLongTask = (elapsedMs: number) => {
  if (elapsedMs > pageStats.pageMaxLongTask) {
    pageStats.pageMaxLongTask = elapsedMs;
  }
};

type ReportableEvent = {
  elapsedMs: number;
  absoluteStartTimeMs: number; // note that this is based on DateTime.now(), not performance.now()
  name: string;
  context?: Record<string, any>;
  variables?: Record<string, unknown>;
};
export const reportTimedEvent = (
  {elapsedMs, absoluteStartTimeMs, name, context, variables}: ReportableEvent,
  ddLogger: LogsGlobal = getDatadogLogs()
) => {
  const relativeStartTimeMs = absoluteStartTimeMs - pageStats.startTimeMs;
  const {alwaysLog, logToRum, ...restContext} = context ?? {};
  if (elapsedMs <= 1 && !alwaysLog) {
    // short circuit for this common case
    return;
  }

  let severityColor = 'black';
  if (elapsedMs > 500) {
    severityColor = 'blue';
  }
  if (elapsedMs > 1000) {
    severityColor = 'orange';
  }
  if (elapsedMs > 5000) {
    severityColor = 'red';
  }
  if (elapsedMs > CONSOLE_REPORT_SLOW_EVENT_MILLIS) {
    perfConsoleLog(
      `%cSlow event, started @${relativeStartTimeMs}ms, elapsed: ${elapsedMs}ms: ${name} `,
      // it's hard to style console log lines so they work well normally & in dark mode,
      // so just set a background color
      `color: ${severityColor}; background: #f0f0f0;`,
      context ?? ''
    );
  }

  /**
   * ###############################################################
   * ##################### Ignorable conditions ####################
   * ###############################################################
   */
  /**
   * we don't want to log anything where the elapsed time is too short to be interesting
   * Network conditions get handled different based on kind:
   * 1. GraphQL events we don't throttle much (anything over 50ms qualifies) so we can do meaningful perf analysis
   * 2. `PerfObserver:` network events we only want to log when they're slow, otherwise they overwhelm DD logs
   */
  const isNetworkContext = context?.isNetwork ?? false;
  const elapsedTimeDoesNotMeetThreshold = isNetworkContext
    ? elapsedMs < MIN_NETWORK_ELAPSED_TIME_MILLIS // network events have a different time threshold
    : elapsedMs < MIN_REPORT_TO_SERVER_TIME_MILLIS; // elapsed time is too short to be interesting

  const isShortRunningPerfObserver =
    name.includes('PerfObserver: Network') &&
    elapsedMs < THROTTLED_MIN_NETWORK_ELAPSED_TIME_MILLIS;

  const ignoreConditions = context?.alwaysLog
    ? []
    : [elapsedTimeDoesNotMeetThreshold, isShortRunningPerfObserver];

  /**
   * ###############################################################
   * ################### End Ignorable conditions ##################
   * ###############################################################
   */

  const ddContext = {
    perfEvent: name,
    elapsedMs,
    variables,
    ...restContext,
  };

  // Datadog vitals can't have special characters in the name, so be
  // conservative and scoop them all out. Removes anything that's not a letter
  // or a number and replaces them with '__'. Multiple matched characters in a
  // row will be replaced by a single '__' pair.
  const repairedName = name.replace(/[^a-zA-Z0-9]+/g, '__');
  getDatadogRum().addDurationVital(repairedName, {
    startTime: absoluteStartTimeMs,
    duration: elapsedMs,
    context: ddContext,
  });

  if (!ignoreConditions.includes(true)) {
    // if any of the ignore conditions are true, don't log the event
    const leftAlignedNum = elapsedMs.toString().padStart(6, ' ');
    // 6 because that's enough to show 99s, which is more than we care about.

    ddLogger.logger.warn(
      // wording is slightly awkward, but it's easier to compare times like this
      `${PERF_LOG_PREFIX} Perf event: ${leftAlignedNum}ms for ${name}`,
      ddContext
    );

    if (logToRum) {
      logCustomActionToDatadog(name, ddContext);
    }
  }
};
